#GameBoard {
	position: relative;
	margin: auto;
	border-style: solid;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;

}

#GameBoard .Block {
	position: absolute;
}

#GameBoard #GameOver {
	text-align: center;
	height: 100%;
	flex-direction: column;
	display: flex;
	justify-content: space-around;
	
}

#GameBoard #Score {
	position: relative;
	top: 105%;
	text-align: center;
	font-weight: bold;
}

#GameBoard .stopbtn{
	position: relative;
	top: 350%;
	text-align: center;
	font-weight: bold;

}

#GameBoard #GameOverText {
	color: #ca0000;
	font-weight: bold;
	text-decoration-line: underline;
}

#GameBoard #PressSpaceText {
	color: #2e7ecb;
	font-weight: bold;
}

.textWrapper{
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
	font-size: 1.5rem;
	/* height: 80%; */
}

#instructions{
	margin-bottom:0;	
}

.subTitle{
	margin-top:2px;
	font-size: 1rem;
}

